import { More } from 'dino_ui_tools'
import * as yup from 'yup'
import { VALIDATION_MESSAGES } from '../../consts'
import {
  DOCUMENT_NUMBER,
  DOCUMENT_TYPE,
  ARCA_CONFIRMED,
  SOCIAL_CARD_NUMBER,
  DOC_NUMBER_REGEXP,
  DOC_TYEPS,
  ID_CARD_REGEXP,
  LOAN_CONFIRMED,
  MONEY_CONFIRMED
} from './consts'

import './index.scss'

export const getCheckboxLabel = (href: string, labeltext: string, labelFullText: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <More expandedText={labelFullText} collapsedText={labeltext} />
    </div>
  )
}

export const isSocialNumberValid = (value = '') => {
  const splitRequiredNumbers = value.split('', 4)
  const firstPart = splitRequiredNumbers.slice(0, 2)
  const secondPart = splitRequiredNumbers.slice(2, 4)
  const devilString = value.includes('666')

  const numerifyFirst = Number(firstPart.join(''))
  const numerifySecond = Number(secondPart.join(''))

  const isValidFirtsNumber =
    (numerifyFirst >= 11 && numerifyFirst <= 41) || (numerifyFirst >= 51 && numerifyFirst <= 81)
  const isValidSecondNumber =
    (numerifySecond >= 1 && numerifySecond <= 12) ||
    (numerifySecond >= 21 && numerifySecond <= 32) ||
    numerifySecond === 14 ||
    numerifySecond === 34
  return !(!isValidFirtsNumber || !isValidSecondNumber || devilString || !value)
}

export const VALIDATION_SCHEME = (docType: string) => {
  return yup.object({
    [DOCUMENT_NUMBER]: yup
      .string()
      .required(VALIDATION_MESSAGES.required)
      .test('valid', VALIDATION_MESSAGES.invalid, (val) =>
        docType === DOC_TYEPS.id ? val.length === 9 : true
      )
      .matches(
        docType === DOC_TYEPS.passport ? DOC_NUMBER_REGEXP : ID_CARD_REGEXP,
        VALIDATION_MESSAGES.invalid
      ),
    [DOCUMENT_TYPE]: yup.string().required(VALIDATION_MESSAGES.required),
    [ARCA_CONFIRMED]: yup
      .boolean()
      .required(VALIDATION_MESSAGES.required)
      .oneOf([true], VALIDATION_MESSAGES.required),
    [MONEY_CONFIRMED]: yup
      .boolean()
      .required(VALIDATION_MESSAGES.required)
      .oneOf([true], VALIDATION_MESSAGES.required),
    [LOAN_CONFIRMED]: yup
      .boolean()
      .required(VALIDATION_MESSAGES.required)
      .oneOf([true], VALIDATION_MESSAGES.required),
    // [SOCIAL_CARD_NUMBER]: yup
    //   .string()
    //   .required(VALIDATION_MESSAGES.required)
    //   .test(
    //     'valid',
    //     VALIDATION_MESSAGES.invalid,
    //     (val) => val?.indexOf('_') === -1 && isSocialNumberValid(val)
    //   )
  })
}
