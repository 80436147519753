import { useContext, useMemo, useState } from 'react'
import {
  Select,
  Text,
  FormContainer,
  FormField,
  Input,
  Checkbox,
  WithFormFeedback,
  Modal,
  Heading,
  More
} from 'dino_ui_tools'
import { AGGREEMENT } from '../../consts'
import {
  SOCIAL_CARD_NUMBER,
  ARCA_CONFIRMED,
  DOCUMENT_TYPE,
  DOCUMENT_NUMBER,
  ID_DATA_OPTIONS,
  DOC_TYEPS,
  MONEY_CONFIRMED,
  LOAN_CONFIRMED
} from './consts'
import { VALIDATION_SCHEME } from './helpers'
import './index.scss'
import FormSubmitButton from '../FormSubmitButton'
import withWrapper from '../FormWrapper'
import { useProfile } from '../../hooks'

import { useSubmitDocuments } from '../../hooks/useSubmitDocuments'
import { AppContext } from '../../context'
import { useParams } from 'react-router-dom'

const PersonalData = () => {
  const { profileInfo, updateProfileInfo } = useProfile()
  const { id } = useParams()
  const { isDocumentsVerified } = useContext(AppContext)
  const { identificationDocument } = profileInfo
  const _identificationDocument = identificationDocument || { typeCode: '', number: '' }
  const initialDocType = _identificationDocument.typeCode
    ? _identificationDocument.typeCode
    : ID_DATA_OPTIONS[0].value

  const INITIAL_VALUES = {
    // [SOCIAL_CARD_NUMBER]: socialSecurityNumber || '',
    [DOCUMENT_TYPE]: initialDocType,
    [DOCUMENT_NUMBER]: _identificationDocument.number || ''
  }

  const [doctype, setDoctype] = useState(initialDocType)
  const [isConfirmPopupVisible, setConfirmPopupVisibility] = useState(false)
  const [currentFormData, setCurrentFormData] = useState(null)

  const openSocialNumberConfirmPopup = () => setConfirmPopupVisibility(true)
  const closeSocialNumberConfirmPopup = () => setConfirmPopupVisibility(false)
  const checkboxLable3 = useMemo(() => {
    return (
      <span>
        {`Հաստատում եմ `}
        <a className="link" href={AGGREEMENT} target="_blank" rel="noreferrer">
          բանկին տրվող համաձայնությունները
        </a>
      </span>
    )
  }, [])
  const checkboxLable1 = useMemo(() => {
    return (
      <More
        expandedText={
          'Բանկում դրամական պարտավորություններ ստանձնելու/ունենալու նպատակով իմ վարկավորման հնարավորությունները դիտարկելու (ներառյալ` նախկինում իմ մասին ստացված վարկային զեկույցի տեղեկատվությունը թարմացնելու) համար: Ազդեցություն վարկային ամփոփ գնահատականի վրա` իջեցնում է: Համաձայնության կիրառման և գործողության ժամկետ` համաձայնության տրամադրման պահից մինչև տրամադրման համար հիմք հանդիսացած Վարկային հայտի դիտարկման ավարտը, բայց ոչ ավել, քան 3 ամիս:'
        }
        collapsedText={'Բանկում դրամական պարտավորություններ ստանձնելու/ունենալու'}
      />
    )
  }, [])
  const checkboxLable2 = useMemo(() => {
    return (
      <More
        expandedText={
          'Իմ վարկ/եր/ի սպասարկման ընթացիկ մոնիթորինգն իրականացնելու (ներառյալ` նախկինում իմ մասին ստացված վարկային զեկույցի տեղեկատվությունը թարմացնելու) և նախկինում իմ մասին ստացված նպատակով: Ազդեցություն վարկային ամփոփ գնահատականի վրա` չի ազդում: Համաձայնության կիրառման և գործողության ժամկետ` համաձայնության տրամադրման պահից մինչև տրամադրման համար հիմք հանդիսացած Վարկային հայտի/պայմանագրի շրջանակներում իմ և Բանկի միջև ծագած իրավահարաբերության ամբողջ ժամանակահատվածում:'
        }
        collapsedText={'Իմ վարկ/եր/ի սպասարկման ընթացիկ մոնիթորինգն իրականացնելու'}
      />
    )
  }, [])

  const { submitDocuments } = useSubmitDocuments()

  const onFormSubmit = (data) => {
    // openSocialNumberConfirmPopup()
    // setCurrentFormData(data)
    onSubmit(data)
  }

  const onSubmit = (data) => {
    const withLoading = true
    const { typeCode, number } = data
    const newData = {
      ...profileInfo,
      // socialSecurityNumber,
      identificationDocument: {
        ...profileInfo.identificationDocument,
        typeCode: typeCode,
        number,
        authority: ''
      }
    }
    updateProfileInfo(newData, id, submitDocuments, withLoading)
  }

  return (
    <div className="personal_data_container">
      {/*<Modal*/}
      {/*  isOpen={isConfirmPopupVisible}*/}
      {/*  onClose={closeSocialNumberConfirmPopup}*/}
      {/*  onSumbit={onSubmit}*/}
      {/*  title="Համոզվեք, որ ճիշտ եք նշել"*/}
      {/*  buttonProps={{*/}
      {/*    confirm: {*/}
      {/*      buttonText: 'Հաստատել'*/}
      {/*    },*/}
      {/*    cancel: { buttonText: 'Խմբագրել' }*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div>*/}
      {/*    <Text size="small">Սոցիալական քարտ (ՀԾՀ)</Text>*/}
      {/*    <Text size="large" weight="semibold">*/}
      {/*      {currentFormData ? currentFormData[SOCIAL_CARD_NUMBER] : ''}*/}
      {/*    </Text>*/}
      {/*  </div>*/}
      {/*</Modal>*/}
      <Heading className="mb-24" size="xsmall" weight="bold">
        Գնորդի անձնական տվյալներ
      </Heading>

      <FormContainer
        validationScheme={VALIDATION_SCHEME(doctype)}
        initialValues={INITIAL_VALUES}
        onSubmit={onFormSubmit}
      >
        <>
          <div className="form_body">
            {/*<FormField*/}
            {/*  className="mb-24"*/}
            {/*  isControlled*/}
            {/*  name={SOCIAL_CARD_NUMBER}*/}
            {/*  As={(props) => (*/}
            {/*    <Input*/}
            {/*      {...props}*/}
            {/*      disabled={isDocumentsVerified}*/}
            {/*      required*/}
            {/*      label="Սոցիալական քարտ (ՀԾՀ)"*/}
            {/*      mask="9999999999"*/}
            {/*      placeholder="__________"*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            <WithFormFeedback>
              {({ setValue }) => (
                <FormField
                  className="mb-24"
                  isControlled
                  isNeedChangeHandler
                  name={DOCUMENT_TYPE}
                  As={(props) => (
                    <Select
                      {...props}
                      isRequiredField
                      label="Անձը հաստատող փաստաթղթի տեսակ"
                      options={ID_DATA_OPTIONS}
                      setSelectedItem={(selected: any) => {
                        setValue(DOCUMENT_NUMBER, '')
                        setDoctype(selected as DOC_TYEPS)
                      }}
                    />
                  )}
                />
              )}
            </WithFormFeedback>

            <FormField
              className="mb-24"
              name={DOCUMENT_NUMBER}
              As={(props) => <Input {...props} label="Փաստաթղթի համար" required />}
            />

            <Text className="mb-24">
              Սույնով տալիս եմ համաձայնություն առ այն, որ Բանկը առանց ինձ նախապես տեղեկացնելու
              հարցումներ կատարի և ստանա տեղեկություններ ՀՀ ԿԲ Վարկային Ռեգիստրից և/կամ «ԱՔՌԱ Քրեդիտ
              Ռեփորթինգ» ՓԲԸ-ից իմ ներկա և անցյալ դրամական պարտավորությունների մասին` հետևյալ
              նպատակներով.
            </Text>

            <FormField
              className="mb-24"
              isNeedChangeHandler
              isControlled
              name={MONEY_CONFIRMED}
              As={(props) => (
                <Checkbox {...props} label={checkboxLable1} className="aqra_checkbox_lable" />
              )}
            />
            <FormField
              className="mb-24"
              isNeedChangeHandler
              isControlled
              name={LOAN_CONFIRMED}
              As={(props) => (
                <Checkbox {...props} label={checkboxLable2} className="aqra_checkbox_lable" />
              )}
            />
            <FormField
              className="mb-24"
              isNeedChangeHandler
              isControlled
              name={ARCA_CONFIRMED}
              As={(props) => (
                <Checkbox {...props} label={checkboxLable3} className="aqra_checkbox_lable" />
              )}
            />
          </div>
          <FormSubmitButton />
        </>
      </FormContainer>
    </div>
  )
}
export default withWrapper(PersonalData)
